
      if (import.meta.env.MODE !== "development") {
        const script = document.createElement("script");
        script.id = "Cookiebot";
        script.src = "https://consent.cookiebot.com/uc.js";
        script.dataset.cbid = "7c745e21-4cd2-445c-94cb-dfc37d546a57";
        script.dataset.blockingmode = "auto";
        script.type = "text/javascript";
        document.body.appendChild(script);
      }
    